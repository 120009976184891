<i18n src="./translations"></i18n>

<template>
  <div
    :class="classes"
    :data-preload="[$options.name]"
    data-prefetch="MiniCart"
  >
    <HeaderButton
      modifier="headerButton"
      class="desktopLabel qa-HeaderCartButton-headerButton gtm--HeaderCartButton-headerButton"
      :icon="icon"
      :label="$t('header-cart-button.cart')"
      :title="$t('header-cart-button.cart')"
      :href="frontFacingPath('checkout.cart.details')"
      :count="count"
      :on-click="clickHandler"
      :toggle="!!count"
      :is-active="
        (minicart && miniCartActive) || (!isMobile && latestNotificationActive)
      "
    />
    <client-only>
      <transition
        enter-class="transitionInitial"
        enter-to-class="transitionGoal"
        enter-active-class="transitionEnterActive"
        leave-class="transitionGoal"
        leave-to-class="transitionInitial"
        leave-active-class="transitionLeaveActive"
      >
        <AddedToCartNotification
          v-show="latestNotificationActive"
          :class="
            latestNotificationActive && 'qa-addToCartNotification__active'
          "
          :product="latestAddedProduct"
        />
      </transition>

      <transition
        enter-class="transitionInitial"
        enter-to-class="transitionGoal"
        enter-active-class="transitionEnterActive"
        leave-class="transitionGoal"
        leave-to-class="transitionInitial"
        leave-active-class="transitionLeaveActive"
      >
        <MiniCart
          v-if="miniCartProducts && miniCartProducts.length > 0"
          v-show="miniCartActive"
          :is-active="miniCartActive || latestNotificationActive"
          :products="miniCartProducts"
          :payment-total="String(minicart.amounts.paymentTotal)"
          class="miniCart"
        />
      </transition>
    </client-only>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ClientOnly from 'vue-client-only';
import HeaderButton from 'Components/01-atoms/header-button/HeaderButton.vue';
import MiniCart from 'Components/03-organisms/mini-cart/MiniCart';
import AddedToCartNotification from 'Components/03-organisms/added-to-cart-notification/AddedToCartNotification';
import globalMixin from 'Libs/mixins/globalMixin';
import isArray from 'lodash/isArray';
import { has } from 'lodash';

export default {
  name: 'HeaderCartButton',
  components: {
    HeaderButton,
    ClientOnly,
    MiniCart,
    AddedToCartNotification,
  },
  mixins: [globalMixin],
  data: () => ({
    icon: {
      name: 'cart',
      width: '21',
      height: '21',
    },
  }),
  computed: {
    ...mapState('core', {
      isMobile: ({ viewport }) => viewport.range !== 'desktop',
    }),
    ...mapState('header', {
      miniCartActive: (state) => state.miniCart.open,
      latestNotificationActive: (state) =>
        state.latestAddedProductNotification.open,
      latestAddedProductId: (state) =>
        state.latestAddedProductNotification.productId,
    }),
    ...mapState('miniCart', {
      minicart: (state) => state.data,
    }),
    ...mapGetters('miniCart', {
      miniCartProducts: 'getMiniCartProducts',
      count: 'getMiniCartTotalCount'
    }),
    latestAddedProduct() {
      if (isArray(this.miniCartProducts) && this.latestAddedProductId) {
        return this.miniCartProducts.find(
          (product) => product.productId === this.latestAddedProductId
        );
      }
      return null;
    },
  },
  watch: {
    miniCartActive(isOpen) {
      if (isOpen) {
        this.trackActiveMinicart();
      }
      this.trackActiveMinicart();
    },
  },
  methods: {
    ...mapActions('header', ['toggleMiniCart']),
    ...mapActions('dataLayer', ['trackActiveMinicart']),
    clickHandler(event) {
      if (
        this.miniCartProducts?.length &&
        window.matchMedia('(min-width: 900px)').matches &&
        window.location.pathname !==
          this.frontFacingPath('checkout.cart.details')
      ) {
        event.preventDefault();
        this.toggleMiniCart();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.headerCartButton {
  display: block;
  position: relative;

  .miniCart {
    right: 0;
    left: unset;
    top: 100%;

    &::before {
      content: none;
    }
  }
}

.button {
  position: relative;
  white-space: nowrap;
}

.desktopLabel {
  span {
    display: inline-block;
    font-weight: 400;
    font-size: var(--font-size-SM);
    margin-left: 8px;

    @media (max-width: 899px) {
      display: none;
    }
  }
}

::v-deep {
  .transitionInitial {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-15px);
  }

  .transitionGoal {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  .transitionEnterActive,
  .transitionLeaveActive {
    transition: transform var(--time-S) ease-in, opacity var(--time-S) linear;
  }
}

@media (max-width: 899px) {
  .desktopLabel {
    span {
      display: none;
    }
  }

  .miniCart {
    display: none;
  }
}
</style>
