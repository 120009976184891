<i18n src="./translations"></i18n>

<template>
  <ul :class="[classes, availabilityClasses]" :data-preload="[$options.name]">
    <li class="text">
      <strong> {{ availabilityLabel }}&nbsp;</strong>
      <span v-if="!!availabilityText && !isBusinessCustomer">{{
        availabilityText
      }}</span>
    </li>
  </ul>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin.js';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Availability',
  mixins: [globalMixin],
  props: {
    availabilityKey: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    isNewAvailability: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('core', {
      promiseBanner: ({ promiseBanner }) => promiseBanner,
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      baseUrl: ({ salesChannel }) => salesChannel.baseUrl,
    }),
    ...mapGetters('core', {
      companyInfo: 'getCompanyInformation',
      isBusinessCustomer: 'isBusinessCustomer',
    }),
    isBPlus() {
      return (
        this.isBusinessCustomer &&
        this.baseUrl &&
        this.baseUrl.includes('alpha')
      );
    },
    availabilityClasses() {
      let classArray = [];
      const availabilityKey =
        this.availabilityKey && this.availabilityKey.toLowerCase();

      switch (availabilityKey) {
        case 'instant':
        case 'available':
          classArray = ['available', 'qa-Availability--available'];

          if (this.promiseBanner.visible) {
            classArray = [
              'available',
              'qa-Availability--available',
              'qa-Availability--available-today',
            ];
          }
          return classArray;
        case 'available_today':
          classArray = ['available', 'qa-Availability--available'];
          return classArray;
        case '2to3workdays':
          classArray = ['available--2-3-days', 'qa-Availability--available'];

          if (this.isBPlus) {
            classArray = [
              'available--2-3-days-bPlusCustomer',
              'qa-Availability--available',
            ];
          }
          return classArray;
        case 'delivery_within_3_4_working_days':
        case '3to4workdays':
        case '3to5workdays':
          classArray = ['available--3-5-days', 'qa-Availability--available'];

          if (this.isBPlus) {
            classArray = [
              'available--3-5-days-bPlusCustomer',
              'qa-Availability--available',
            ];
          }
          return classArray;
        case '5to10workdays':
          return ['available--5-10-days', 'qa-Availability--available'];
        case 'delayed':
        case 'available_soon':
        case 'available_on_request':
        case 'out_of_stock':
        case '2to4weeks':
          classArray = ['available_soon', 'qa-Availability--available_soon'];
          return classArray;
        case 'unavailable':
        case 'notavailable':
        case 'not_available':
          classArray = ['not_available', 'qa-Availability--not_available'];
          return classArray;
        default:
          return '';
      }
    },
    availabilityLabel() {
      let label = this.$t(
        `availability.${this.availabilityKey.toLowerCase()}.label`
      );

      if (
        this.availabilityKey === '3to4workdays' ||
        this.availabilityKey === '3to5workdays'
      ) {
        label = this.$t('availability.3to5workdays.label');
      }
      if (!this.isNewAvailability) {
        label = this.label;
      }
      if (this.availableToday) {
        label = this.$t('availability.available_today.label');
      }
      return label;
    },
    availabilityText() {
      let text = '';
      if (this.availableToday) {
        text = this.$t('availability.available_today.text');
      }
      return text;
    },
    availableToday() {
      const availabilityKey = this.availabilityKey.toLowerCase();
      return (
        ['available_today'].includes(availabilityKey) &&
        this.promiseBanner.visible
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.availability {
  list-style-position: outside;
  padding: 0 0 0 var(--space-0--half);

  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding-left: var(--space-1--half);
    font-size: var(--font-size-S);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    bottom: 2px;
  }

  li::before {
    flex-shrink: 0;
    content: '';
    width: var(--space-1);
    height: var(--space-1);
    border-radius: 50%;
    background: var(--color-rolling-stone);
    margin-right: var(--space-0--half);
    top: 2;
    left: -3px;
    display: inline-block;
    position: absolute;
  }

  &.available {
    color: var(--color-japanese-laurel);

    li {
      font-weight: bold;
    }

    li::before {
      background: var(--color-japanese-laurel);
    }
  }

  &.available--today {
    li {
      align-items: start;
      color: var(--color-japanese-laurel);
      font-weight: bold;
    }

    li::before {
      top: 3px;
      background: var(--color-japanese-laurel);
    }
  }

  &.available--2-3-days-bPlusCustomer,
  &.available--3-5-days-bPlusCustomer,
  &.available--5-10-days {
    color: var(--color-nero);

    li::before {
      background: var(--color-24-blue);
    }
  }

  &.available--2-3-days,
  &.available--3-5-days {
    color: var(--color-nero);

    li::before {
      background: var(--color-japanese-laurel);
    }
  }

  &.available_soon {
    color: var(--color-nero);

    li::before {
      background: var(--color-amber);
    }
  }

  &.not_available {
    color: var(--color-nero);

    li::before {
      background: var(--color-monza);
    }
  }
}
$verticalSpace: 4px;

span {
  color: var(--color-nero);
  font-size: var(--font-size-SM);
  flex-shrink: 0;
  margin-bottom: $verticalSpace;
  display: block;
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}

@media #{$_mediaSDown} {
  .availability {
    flex-wrap: wrap;
  }
}
</style>
