<i18n src="./translations"></i18n>

<template>
  <div
    :class="[classes, { 'product-row--compact': isCompact }]"
    class="product-row"
    :data-preload="[$options.name]"
  >
    <div
      class="product-row__content content"
      :class="{
        'product-row__content--with-deposit': prices.unit.deposit.length,
      }"
    >
      <AdvancedImage
        v-if="product.images && product.images[0] && product.images[0].url"
        class="content__product-image"
        :src="product.images[0].url"
        :alt="product.images[0].alt || ''"
        :config="{ q: 100, w: 100 }"
      />
      <NoImageSvg
        v-else
        class="content__product-image-no-image"
        :lazy="true"
        :inline="false"
      />
      <div class="content__information information">
        <div class="information__brand qa-ProductTile__brand">
          <MaskedLink
            :redirect-to="product.productUrl"
            class="qa-ProductTile__tracking"
            :text="product.brand"
          />
        </div>
        <div class="information__title qa-ProductTile__title">
          <MaskedLink
            :redirect-to="product.productUrl"
            class="qa-ProductTile__tracking"
            :text="product.name"
          />
        </div>
        <div class="information__article">
          {{ $t('sku') }} {{ product.displaySku }}
        </div>
        <Availability
          class="information__availability qa-ProductTile__availabilty"
          :availability-key="availabilityKey"
          :is-new-availability="isNewAvailability"
          :label="
            product && isNewAvailability
              ? ''
              : product.availability && product.availability.label
          "
        />
      </div>
      <div class="content__numbers">
        <div class="content__unit-prices unit-prices qa-priceList__unit">
          <Price
            v-for="(price, key) in prices.unit.retail"
            v-show="!isNetPrice(price)"
            :key="key"
            :label="formatLabel(price, key)"
            :value="`${normalizePrice(price.price, currency)}`"
            class="unit-prices__price"
            :class="{
              'unit-prices__price--large': key === 0,
              'unit-prices__price--discounted':
                product.isDiscountCodeApplied && key !== 0,
            }"
          />
        </div>
        <!-- [izerozlu] The <div> in the next line is unncessary but keeping it since it has a qa identifier attached to it -->
        <div class="content__quantity quantity qa-ProductTile__quantity">
          <QuantitySelector
            v-if="!isReadonly"
            class="quantity__selector"
            :label="$t('quantity')"
            :value="product.quantityAmount"
            :choices="product.quantityOptions"
            :threshold="product.quantityThreshold"
            :product="product"
            :is-cart-and-checkout="true"
            select-class="qa-ProductTile__wishlist--quantity"
            @onQuantityChange="
              ({ quantity }) => $emit('quantity-change', quantity)
            "
            @maxQuantityReached="handleMaxQuantityReached"
          />
          <div v-else class="quantity__selector--readonly">
            <input
              class="quantity__readonly-value"
              :value="product.quantityAmount"
              disabled
            />
          </div>
        </div>
        <div class="content__total-prices total-prices">
          <Price
            v-for="(price, key) in prices.total.retail"
            v-show="!isNetPrice(price)"
            :key="key"
            :label="formatLabel(price, key)"
            :value="`${normalizePrice(price.price, currency)}`"
            :is-kvi="product.isKvi"
            class="total-prices__price"
            :class="{
              'total-prices__price--bold total-prices__price--large': key === 0,
              'total-prices__price--discounted':
                product.isDiscountCodeApplied && key !== 0,
              'total-prices-price-is-kvi': product.isKvi,
            }"
          />
        </div>
        <div
          v-if="product.isDiscountCodeApplied"
          class="content__discount-percentage"
        >
          {{
            $t('discount_percentage', {
              '%percentage%': product.discountCode.discountPercentage,
            })
          }}
        </div>
      </div>
      <div v-if="isMaxQuantityReached" class="max-quantity-error">
        <Icon name="warning" width="17" class="icon" />
        {{ $t('max-quantity-error') }}
      </div>
      <div
        v-if="prices.unit.deposit.length"
        class="content__deposits deposits"
        data-testid="products-surcharge"
        :class="[
          { 'content__deposits--discounted': product.isDiscountCodeApplied },
          { 'content__deposits--hidden': isCart },
        ]"
      >
        <div
          v-for="(price, index) in prices.unit.deposit"
          :key="price.label"
          class="deposits__deposit deposit"
        >
          <span class="deposit__label">{{ price.label }}</span>
          <span v-if="price.price" class="deposit__unit">
            {{ normalizePrice(price.price, currency) }}
          </span>
          <span class="deposit__quantity">
            {{ $t('pieces', { '%amount%': product.quantityAmount }) }}
          </span>
          <span v-if="prices.total.deposit[index].price" class="deposit__total">
            {{ normalizePrice(prices.total.deposit[index].price, currency) }}
          </span>
        </div>
      </div>
    </div>
    <TileActions
      :actions-list="{ actions: processedActions }"
      :handle-actions="false"
      @action="handleAction"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import globalMixin from 'Libs/mixins/globalMixin';
import { normalizePrice } from 'Libs/helpers/format-price';

import Availability from 'Components/01-atoms/availability/Availability';
import QuantitySelector from 'Components/01-atoms/quantity-selector/QuantitySelector';
import Price from 'Components/01-atoms/price/Price';
import TileActions from 'Components/02-molecules/tile-actions/TileActions';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage.vue';
import NoImageSvg from 'Components/00-generated/NoImageSvg.vue';
import Icon from 'Components/00-generated/Icon.vue';

export default {
  name: 'ProductRow',
  components: {
    Availability,
    QuantitySelector,
    Price,
    TileActions,
    MaskedLink,
    AdvancedImage,
    NoImageSvg,
    Icon,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    actions: {
      type: Array,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    isCart: {
      type: Boolean,
      default: false,
    },
    hideNetPrice: {
      // Hide net prices only for non B+ customers
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMaxQuantityReached: false,
    };
  },
  computed: {
    ...mapGetters('core', ['isBusinessPlusCustomer']),
    ...mapState('core', {
      currency: (state) => state.currency,
      baseUrl: ({ salesChannel }) => salesChannel.baseUrl,
      isDirectDelivery: ({ requestData }) =>
        requestData.isDirectDelivery ?? false,
    }),
    isNewAvailability() {
      if (this.product && this.product.availability) {
        const { availability } = this.product;
        return (
          typeof availability === 'string' || availability instanceof String
        );
      }
      return false;
    },
    availabilityKey() {
      if (this.isNewAvailability) {
        return this.product.availability;
      }
      return this.product.availability ? this.product.availability.key : '';
    },
    prices() {
      const priceBase = this.product.isDiscountCodeApplied
        ? this.product.discountCode
        : this.product;

      const filterDeposits = (deposits) => {
        let updatedDeposits = [...deposits];

        if (this.isDirectDelivery) {
          // Remove Dangerous and Bulky goods fees for B+ Direct Delivery customers
          updatedDeposits = updatedDeposits.filter(
            ({ label }) =>
              label !== 'Gefahrgutzuschlag' && label !== 'Sperrgutzuschlag' // B+ is only for DE channel so no need for translations yet
          );
        }

        if (this.hideNetPrice && !this.isBusinessPlusCustomer) {
          updatedDeposits = updatedDeposits.filter(
            ({ label }) => label !== this.$t('net-price-label')
          );
        }
        return updatedDeposits;
      };

      const getRetailUnitPrices = () => {
        if (this.product.isDiscountCodeApplied) {
          return priceBase.unitPrices.slice(0, 2);
        }
        return this.isBusinessPlusCustomer
          ? priceBase.unitPrices.slice(1, 2)
          : priceBase.unitPrices.slice(0, 1);
      };

      const getRetailTotalPrices = () => {
        if (this.product.isDiscountCodeApplied) {
          return priceBase.totalPrices.slice(0, 2);
        }
        return this.isBusinessPlusCustomer
          ? priceBase.totalPrices.slice(1, 2)
          : priceBase.totalPrices.slice(0, 1);
      };

      return {
        unit: {
          retail: getRetailUnitPrices(),
          deposit: filterDeposits(
            this.isBusinessPlusCustomer
              ? this.product.unitPrices.slice(2).filter(({ price }) => !!price)
              : this.product.unitPrices.slice(1).filter(({ price }) => !!price)
          ),
        },
        total: {
          retail: getRetailTotalPrices(),
          deposit: filterDeposits(
            this.isBusinessPlusCustomer
              ? this.product.totalPrices.slice(2).filter(({ price }) => !!price)
              : this.product.totalPrices.slice(1).filter(({ price }) => !!price)
          ),
        },
      };
    },
    processedActions() {
      return this.actions.reduce((actions, action) => {
        if (action.type === 'wishlist') {
          action.icon = this.product.inWishlist
            ? 'wishlist_filled_heart'
            : 'wishlist_heart';
        }

        return [...actions, action];
      }, []);
    },
  },
  methods: {
    handleAction({ type }) {
      this.$emit('action', { type, product: this.product });
    },
    // [izerozlu] using a formatter with methods is a code smell for Vue but I shouldn't touch the store's data for
    // product prices and keeping a separate data just for formatted prices seemed like a loss compared to this approach.
    normalizePrice(price) {
      return normalizePrice(price, this.currency);
    },
    formatLabel(price, key) {
      if (this.isBusinessPlusCustomer && key === 0) {
        return ''; // [andrei] Remove 'Net' label for BPlus customer
      }
      return price.label;
    },
    isNetPrice(price) {
      /**
       * This method checks if the current price is of type 'Net'
       * and the current user is not B+ ( then the only price shown is the Net )
       */
      return (
        price.label === this.$t('net-price-label') &&
        this.hideNetPrice &&
        !this.isBusinessPlusCustomer
      );
    },
    handleMaxQuantityReached(isReached) {
      this.isMaxQuantityReached = isReached;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

// [izerozlu] Because of ProductRows' usage in different pages and different layouts, I had to use breakpoints
// different than what we're normally use. And also I did need different breakpoints for "compact" variation
// because of some layouts having side elements in different pages.
$medium-breakpoint--regular: 900px;
$medium-breakpoint--compact: 1200px;

$small-breakpoint--regular: 668px;
$small-breakpoint--compact: 900px;

$tiny-breakpoint--regular: 550px;

.product-row {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  background: var(--color-white);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-black--decorative);
  }
}

.product-tile__image-container {
  padding-right: var(--space-1--half);
}

.content__product-image {
  width: 100px !important;
  max-height: 100px;
  margin: 0 var(--space-2) var(--space-2);

  ::v-deep img {
    min-height: 100px;
    max-height: 100px;
  }
}

.content__product-image-no-image {
  max-height: 100px;
  margin: 0 var(--space-2) var(--space-2);
}

.product-row__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--space-2) 0 0;
  height: 100%;
}

.content__information {
  flex-grow: 1;
  overflow: hidden;
  color: var(--color-mine-shaft);
  margin-right: var(--space-2);
}

.information__brand {
  margin-bottom: var(--space-0--half);

  ::v-deep span {
    font-size: var(--font-size-S);
    line-height: var(--font-size-MML);
    font-weight: bold;
  }
}

.information__title {
  margin-bottom: var(--space-0--half);
  font-weight: bold;
  color: var(--color-mine-shaft);
  font-size: var(--font-size-M);
  line-height: var(--font-size-ML);
  text-overflow: ellipsis;
  overflow: hidden;
}

.information__article {
  margin-bottom: 4px;
  font-size: var(--font-size-S);
  line-height: 20px;
  color: var(--color-mine-shaft);
}

.content__numbers {
  display: flex;
}

.content__quantity {
  margin-right: var(--space-4);
  display: flex;
}

.quantity__selector {
  width: 82px;

  ::v-deep {
    .selectWrapper {
      &::before {
        display: none;
      }

      select {
        padding: 0 var(--space-2);
      }
    }

    .inputWrapper {
      &::before {
        width: 32px;
      }

      input {
        padding: 0 0 0 var(--space-2);
        min-width: 92px;
      }

      svg {
        fill: var(--color-primary) !important;
        right: 0;
      }
    }

    label {
      display: none;
    }
  }
}

.quantity__readonly-value {
  padding: 0 var(--space-2);
  color: var(--color-primary);
  height: 40px;
  width: 64px;
  font-weight: bold;
  font-size: var(--font-size-M);
  line-height: 16px;
  border: 1px solid var(--color-alto) !important;
  border-radius: var(--border-radius-default) !important;
}

.content__unit-prices,
.content__total-prices {
  text-align: right;
}

.content__unit-prices {
  margin-right: var(--space-4);
}

.content__total-prices {
  margin-right: var(--space-2);
  min-width: 120px;
}

.total-prices__price,
.unit-prices__price {
  padding-bottom: var(--space-0--half);
  font-size: var(--font-size-S);

  &--large {
    padding-bottom: var(--space-1);
    font-size: var(--font-size-M);
  }

  &--bold {
    font-weight: bold;
  }

  &--discounted {
    text-decoration: line-through;
  }
}

.total-prices-price-is-kvi {
  display: flex;
  flex-direction: column;

  ::v-deep .price-value {
    margin-right: var(--space-2--half);
  }
}

.content__discount-percentage {
  font-size: var(--font-size-S);
  font-weight: bold;
  line-height: var(--font-size-MML);
  height: min-content;
  width: min-content;
  border-radius: 2px;
  color: var(--color-monza);
  border: 1px solid var(--color-monza);
  justify-content: center;
  padding: 0 var(--space-0--half);
  margin-right: var(--space-2);
  margin-left: auto;
}

.max-quantity-error {
  display: flex;
  align-items: start;
  width: 100%;
  background: var(--color-invalid-red);
  font-size: var(--font-size-M);
  margin: var(--space-2);
  padding: var(--space-2);

  .icon {
    margin-right: var(--space-0--half);
  }
}

.content__deposits {
  flex-basis: 100%;
  background: var(--color-wild-sandish);
  padding: var(--space-2) var(--space-2) var(--space-2) 136px; // The last propert -136px- is necessary for aligning with the top row's prices.

  &--discounted {
    padding-right: var(--space-8--half);
  }

  &--hidden {
    display: none;
  }
}

.deposits__deposit {
  padding-bottom: var(--space-0--half);
  display: flex;
}

.deposit__label {
  flex-grow: 1;
}

.deposit__unit {
  margin-right: var(--space-4);
}

.deposit__quantity {
  width: 82px;
  text-align: right;
}

.deposit__total {
  font-weight: 700;
  width: 155px;
  text-align: right;
}

::v-deep {
  .product-action--primary {
    background: var(--color-primary);

    .icon {
      fill: var(--color-white);
    }
  }
}

@media #{$_mediaLUp} {
  .content__quantity {
    padding: 0;
    flex-grow: 1;
  }

  .information__title {
    word-break: break-word;
    max-width: 260px;
  }
}

@media #{$_mediaMUp} {
  .product-row {
    flex-direction: row;
  }

  .product-row__content {
    flex-direction: row;
  }

  .content__quantity {
    padding: 0;
    flex-grow: 0;
  }
}

@media #{$_mediaMDown} {
  .information__title {
    word-break: break-word;
    max-width: 220px;
  }
}

@mixin medium-breakpoint-styles {
  .product-row__content {
    padding-bottom: 0;
  }

  .content__numbers {
    flex-direction: column;

    > * {
      margin-right: var(--space-2);
      margin-bottom: var(--space-2);
    }
  }

  .content__quantity {
    justify-content: flex-end;
  }

  .content__total-prices {
    order: 1;
  }

  .content__deposits--discounted {
    padding-right: var(--space-2);
  }

  .deposit__unit {
    display: none;
  }

  .deposit__total {
    width: 100px;
  }
}

@mixin small-breakpoint-styles {
  .content__information {
    padding-left: var(--space-2);
  }

  .content__product-image {
    display: none;
  }

  .content__deposits {
    padding-left: var(--space-2);
  }
}

@mixin tiny-breakpoint-styles {
  .product-row__content {
    overflow: auto;
  }

  .content__unit-prices {
    display: none;
  }

  .content__numbers {
    flex-direction: row;
    margin-top: var(--space-2);
    padding-left: var(--space-2);
  }

  .content__quantity {
    flex-grow: 1;
    justify-content: flex-start;
  }

  .quantity__selector {
    width: 120px;
  }

  .deposit__total {
    width: 140px;
  }
}

.product-row {
  @media (max-width: #{$medium-breakpoint--regular}) {
    @include medium-breakpoint-styles;
  }

  @media (max-width: $small-breakpoint--regular) {
    @include small-breakpoint-styles;
  }

  // [izerozlu] Tiny breakpoint does not need a "compact" variation.
  @media (max-width: #{$tiny-breakpoint--regular}) {
    @include tiny-breakpoint-styles;
  }
}

.product-row--compact {
  @media (max-width: #{$medium-breakpoint--compact}) {
    @include medium-breakpoint-styles;
  }

  @media (max-width: $small-breakpoint--compact) {
    @include small-breakpoint-styles;
  }
}
</style>
