<template>
  <component
    :is="isLink ? 'a' : 'span'"
    :href="isLink ? url : undefined"
    :class="[classes, `logo_${theme}`]"
    :data-preload="[$options.name]"
    @click="handleRootClick()"
  >
    <img
      class="logoSvg icon logoLarge qa-logo"
      alt="Kfzteile24 Logo"
      width="180"
      :srcset="`${resolveFile(
        `images/logo/logo_${theme}.png`
      )} 4x, ${resolveFile(`images/logo/logo_${theme}--small.png`)} 1x`"
      :src="resolveFile(`images/logo/logo_${theme}.png`)"
    />
    <p style="color: #ffffff; display: none;">v2</p>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

import './images/logo_kfz24.png';
import './images/logo_kfz24--small.png';
import './images/logo_autoteile24.png';
import './images/logo_autoteile24--small.png';
import './images/logo_kfzteile-com.png';
import './images/logo_kfzteile-com--small.png';
import './images/logo_carpardoo.png';
import './images/logo_carpardoo--small.png';
import './images/logo_rebranding.png';
import './images/logo_tirendo.png';
import './images/logo_tirendo--small.png';

import Icon from 'Components/00-generated/Icon';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'Logo',
  components: {
    Icon,
  },
  mixins: [globalMixin],
  props: {
    isLink: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('core', ['isGermanSalesChannel', 'isMobileViewport']),
  },
  methods: {
    handleRootClick() {
      if (this.isLink && this.url) {
        location.assign(this.url);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.logo {
  max-height: 44px;
  max-width: 150px;
  overflow: hidden;
  display: block;
  object-fit: contain;
  cursor: pointer;

  @media #{$_mediaMDown} {
    max-width: 115px;
  }

  @media #{$_mediaSDown} {
    height: 20px;
    width: 102px;

    &--newHeader {
      height: inherit;
      display: flex;
    }
  }
}

.logo_tirendo {
  width: 100%;
}

.logoSvg {
  width: 100%;
}

@media #{$_mediaMDown} {
  .logoSvg {
    margin: auto;
  }
}
</style>
