import Vuex from 'vuex';

import headerModule from './modules/header';
import newsletterModule from './modules/newsletter';
import searchModule from './modules/search';
import dataLayerModule from './modules/data-layer';

import messagesModule from './modules/messages';
import coreModuleFactory from './modules/core';
import navigationModuleFactory from './modules/navigation';
import sideNavigationModuleFactory from './modules/side-navigation';
import carSelectionModuleFactory from './modules/car-selection';
import miniCartModuleFactory from './modules/mini-cart';
import wishlistModuleFactory from './modules/wishlist';
import cartModuleFactory from './modules/cart';
import productsListModuleFactory from './modules/products-list';
import seoCarCategoriesModuleFactory from './modules/seo-car-categories';
import seoBrandCategoriesModuleFactory from './modules/seo-brand-categories';
import plpFiltersModuleFactory from './modules/plpFilters';
import cookiesMainModule from './modules/cookies-main';
import breadcrumbsModuleFactory from './modules/breadcrumbs';
import searchResultsModule from './modules/search-results';
import renderingSalesChannelConfig from './modules/rendering-saleschannel-config';
import ordersModule from './modules/orders';
import checkoutModuleFactory from './modules/checkout';

export function createStore({ coreState = {} }) {
  const {
    brandCategory,
    breadcrumbs,
    carCategory,
    carSelection,
    content,
    headerCartButton,
    headerWishlistButton,
    menu,
    sideNavigation,
  } = coreState.placeholders;

  const core = coreModuleFactory(coreState);
  const store = new Vuex.Store({
    modules: {
      core,
      dataLayer: dataLayerModule,
      header: headerModule,
      newsletter: newsletterModule,
      search: searchModule,
      messages: messagesModule,
      cookiesMain: cookiesMainModule,
      checkout: checkoutModuleFactory(content),
      navigation: navigationModuleFactory(menu),
      sideNavigation: sideNavigationModuleFactory(sideNavigation),
      carSelection: carSelectionModuleFactory(carSelection),
      miniCart: miniCartModuleFactory(headerCartButton),
      wishlist: wishlistModuleFactory(headerWishlistButton),
      cart: cartModuleFactory(content),
      productsList: productsListModuleFactory(content),
      seoCarCategories: seoCarCategoriesModuleFactory(carCategory),
      seoBrandCategories: seoBrandCategoriesModuleFactory(brandCategory),
      plpFilters: plpFiltersModuleFactory(coreState.placeholders),
      breadcrumbs: breadcrumbsModuleFactory(breadcrumbs),
      searchResults: searchResultsModule(content),
      renderingSalesChannelConfig: renderingSalesChannelConfig(
        core.state.salesChannel.salesChannelId,
        core.state.environment
      ),
      orders: ordersModule,
    },
  });

  if (typeof window !== 'undefined') {
    window.store = store;
  }

  return store;
}
