import AjaxService from '../../../services/AjaxService';
import { toSnakeCase, toCamelCase } from 'Libs/helpers/key-case-converter';
import qs from 'qs';

const CHECKOUT_CREATE_ADDRESS = 'checkout.ajax.step.address.type';
const CHECKOUT_EDIT_ADDRESS = 'checkout.ajax.step.address.edit';
const CHECKOUT_DELETE_ADDRESS = 'checkout.ajax.step.address.delete';
const CHECKOUT_SHIPPING_OPTION = 'checkout.ajax.checkout.shipping';
const CHECKOUT_STEP_CONFIRMATION = 'checkout.ajax.step';
const CHECKOUT_WISHLIST_ADD_ENDPOINT_KEY =
  'checkout.ajax.checkout.wishlist.add';
const CHECKOUT_UPDATE_QUANTITY_ENDPOINT_KEY =
  'checkout.ajax.checkout.amount.modify';
// const CHECKOUT_FETCH_PHONE = 'checkout.ajax.checkout.address.phone';
// const CHECKOUT_EDIT_ADDRESS_FORM = 'checkout.ajax.step.address.edit.form';

/**
 * Due to the logic in the backend we have to send some properties keys written as snake case
 * Backend cannot change this yet because the same endpoints are used for backoffice and that would
 * require additional changes.
 */
const convertAddressData = (addressData) => {
  const convertedAddress = toSnakeCase(addressData);

  if (convertedAddress.street_1) {
    convertedAddress.street1 = convertedAddress.street_1;
    delete convertedAddress.street_1; // Remove original key to prevent conflicts
  }

  if (convertedAddress.street_2) {
    convertedAddress.street2 = convertedAddress.street_2;
    delete convertedAddress.street_2; // Remove original key to prevent conflicts
  }

  if (convertedAddress.street_3) {
    convertedAddress.street3 = convertedAddress.street_3;
    delete convertedAddress.street_3; // Remove original key to prevent conflicts
  }

  return convertedAddress;
};

const updateZipCodeAllowedState = (shippingTypes, commit) => {
  shippingTypes.forEach(shippingType => {
    if ('zipCodeAllowed' in shippingType) {
      commit('UPDATE_SHIPPING_ZIP_CODE_ALLOWED', { shippingType });
    }
  });
};

export const createCustomerAddress = async (
  { commit, rootState },
  { addressType, addressData }
) => {
  try {
    const { fetchedUrls } = rootState.core;
    const url = fetchedUrls[CHECKOUT_CREATE_ADDRESS].replace(
      '{type}',
      addressType + '?stepId=address'
    );
    addressData.address = convertAddressData(addressData.address);
    addressData.address.salutation = addressData.address.salutation_type;
    addressData.email = addressData.email || '';
    const result = await AjaxService.post(url, JSON.stringify(addressData), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (result.data && result.data.status === 'success') {
      // Set the 'addressId' received from backend
      addressData.address.addressId = result.data.id;

      // Introduce the address in the store address list
      commit('CREATE_CUSTOMER_ADDRESS', {
        addressType,
        addressData: toCamelCase(addressData.address),
      });
      const availableShippingTypes = result.data.result?.shipping?.availableShippingTypes || [];
      updateZipCodeAllowedState(availableShippingTypes, commit);
    }
    return toCamelCase(addressData.address);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Error creating customer address:',
      error.response ? error.response.data : error
    );
    return null;
  }
};

export const updateCustomerAddress = async (
  { commit, rootState },
  { addressId, addressData }
) => {
  try {
    const { fetchedUrls } = rootState.core;
    const url = fetchedUrls[CHECKOUT_EDIT_ADDRESS].replace(
      '{addressId}',
      addressId
    );

    // [andrei] BE needs new_address property name to extract necessary data
    addressData.new_address = addressData.address;
    const result = await AjaxService.post(url, JSON.stringify(addressData), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (result.data && result.data.status === 'success') {
      commit('UPDATE_CUSTOMER_ADDRESS', {
        addressData: toCamelCase({ ...addressData.address, addressId }),
      });
      const availableShippingTypes = result.data.result?.shipping?.availableShippingTypes || [];
      updateZipCodeAllowedState(availableShippingTypes, commit);
    }

    return toCamelCase({ ...addressData.address, addressId });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Error updating customer address:',
      error.response ? error.response.data : error
    );
    return null;
  }
};

export const deleteCustomerAddress = async (
  { commit, rootState },
  { addressId }
) => {
  try {
    const { fetchedUrls } = rootState.core;
    const url = fetchedUrls[CHECKOUT_DELETE_ADDRESS].replace(
      '{addressId}',
      addressId
    );
    const result = await AjaxService.delete(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (result.data) {
      commit('DELETE_CUSTOMER_ADDRESS', { addressId });
    }
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Error deleting customer address:',
      error.response ? error.response.data : error
    );
    return null;
  }
};

export const confirmCustomerAddress = async (
  { commit, rootState, state },
  { billingAddress, shippingAddress = 'SAME', email, password }
) => {
  try {
    const url = '/shop/checkout/ajax/steps/address';
    let payload = {};
    if (!billingAddress.addressId) {
      payload = {
        'billingAddress.salutation': billingAddress.salutation,
        'billingAddress.firstName': billingAddress.firstName,
        'billingAddress.lastName': billingAddress.lastName,
        'billingAddress.countryCode': billingAddress.countryCode,
        'billingAddress.zipCode': billingAddress.zipCode,
        'billingAddress.city': billingAddress.city,
        'billingAddress.street1': billingAddress.street1,
        'billingAddress.street2': billingAddress.street2,
        'billingAddress.street3': billingAddress.street3,
        'billingAddress.phoneNumber': billingAddress.phoneNumber,
        'billingAddress.defaultBillingAddress': true,
        shippingAddressType: 'SAME',
      };
    } else {
      payload = {
        'billingAddress.addressId': billingAddress.addressId,
        shippingAddressType: 'SAME',
      };
    }

    if (shippingAddress !== 'SAME') {
      if (!billingAddress.addressId) {
        payload = {
          ...payload,
          shippingAddressType: 'DIFFERENT',
          'shippingAddress.salutation': shippingAddress.salutation,
          'shippingAddress.firstName': shippingAddress.firstName,
          'shippingAddress.lastName': shippingAddress.lastName,
          'shippingAddress.countryCode': shippingAddress.countryCode,
          'shippingAddress.zipCode': shippingAddress.zipCode,
          'shippingAddress.city': shippingAddress.city,
          'shippingAddress.street1': shippingAddress.street1,
          'shippingAddress.street2': shippingAddress.street2,
          'shippingAddress.street3': shippingAddress.street3,
          'shippingAddress.phoneNumber': shippingAddress.phoneNumber,
          'shippingAddress.defaultBillingAddress': true,
        };
      } else {
        payload = {
          ...payload,
          shippingAddressType: 'DIFFERENT',
          'shippingAddress.addressId': shippingAddress.addressId,
        };
      }
    }

    /**
     * If the user is logged in as GUEST, we need to send the email and password
     * when selecting an address.
     */
    if (!rootState.core.requestData.isLoggedIn && state.data.guestEmail) {
      payload.email = email;
      payload.password = password;
    }

    const result = await AjaxService.post(url, qs.stringify(payload));

    if (result.messages && result.messages[0].type === 'error') return;

    if (result.data && result.data.result) {
      commit(
        'UPDATE_SELECTED_BILLING_ADDRESS',
        result.data.result.address.selectedBillingAddress
      );
      commit(
        'UPDATE_SELECTED_SHIPPING_ADDRESS',
        result.data.result.address.selectedShippingAddress
      );
      commit('UPDATE_CHECKOUT_STEP', { step: 'payment' });
      commit('SET_PAYMENT', { payment: result.data.result.payment });
      const availableShippingTypes = result.data.result?.shipping?.availableShippingTypes || [];
      updateZipCodeAllowedState(availableShippingTypes, commit);
      await updateCheckoutURL('payment');

      /**
       * Update the isLoggedIn property if the GUEST user decides to create an account
       * when confirming the address.
       */
      if (password) {
        commit('core/setIsLoggedIn', true, { root: true });
      }
    }

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Error creating customer address:',
      error.response ? error.response.data : error
    );
    return error;
  }
};

/**
 * [andrei] Select address based on specified address type ( shipping || billing )
 * @param address {Object}
 * @param addressType {String}
 * @returns {Promise<void>}
 */
export const selectAddress = async (
  { commit },
  { address, addressType }
) => {
  if (address) {
    commit(`UPDATE_SELECTED_${addressType.toUpperCase()}_ADDRESS`, address);
  } else {
    console.error('Incompatible address selected !');
  }
};

/**
 * [andrei] Return the user to the Address step in the Checkout section
 */
export const editSelectedAddress = async ({ commit }, updatePath = false) => {
  try {
    const url = '/shop/checkout/ajax/steps/address';
    const request = await AjaxService.get(url);
    if (request.data && request.data.status === 'success') {
      commit(
        'UPDATE_SELECTED_BILLING_ADDRESS',
        request.data.result.selectedBillingAddress
      );
      commit(
        'UPDATE_SELECTED_SHIPPING_ADDRESS',
        request.data.result.selectedShippingAddress
      );
      const availableShippingTypes = request.data.result.shipping?.availableShippingTypes || [];
      updateZipCodeAllowedState(availableShippingTypes, commit);
      commit('UPDATE_CHECKOUT_STEP', { step: request.data.step });
      commit('SET_ADDRESS', request.data.result);
    } else if (request.redirect) {
      window.location.href = request.redirect;
    }

    if (updatePath) await updateCheckoutURL('address');

    return request;
  } catch (err) {
    console.error(err);
    return err;
  }
};

/**
 * [andrei] Return the user to the Payment step in the Checkout section
 */
export const editSelectedPayment = async ({ commit }, updatePath = false) => {
  try {
    const url = '/shop/checkout/ajax/steps/payment';
    const request = await AjaxService.get(url);

    if (request.data && request.data.status === 'success') {
      commit('UPDATE_CHECKOUT_STEP', { step: request.data.step });
      commit('SET_PAYMENT', { payment: request.data.result });
    } else if (request.redirect) {
      window.location.href = request.redirect;
    }

    if (updatePath) await updateCheckoutURL('payment');

    return request;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const goToConfirmationStep = async ({ commit }, updatePath = false) => {
  try {
    const url = '/shop/checkout/ajax/steps/confirmation';
    const request = await AjaxService.get(url);

    if (request.data && request.data.status === 'success') {
      commit('UPDATE_CHECKOUT_STEP', { step: request.data.step });
      commit('SET_PAYMENT', request.data.result);
      commit('SET_ADDRESS', request.data.result.address);
      const availableShippingTypes = request.data.result.shipping?.availableShippingTypes || [];
      updateZipCodeAllowedState(availableShippingTypes, commit);
      commit('UPDATE_CHECKOUT_CART', request.data.result.cart, { root: true });
      // TODO:Implement shipping mutation
    } else if (request.redirect) {
      window.location.href = request.redirect;
    }

    if (updatePath) await updateCheckoutURL('confirmation');

    return request;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const setSelectedPayment = ({ commit }, payment) => {
  commit('SET_SELECTED_PAYMENT', payment);
};

export const setIsValidPaymentSelection = ({ commit }, isValid) => {
  commit('SET_IS_VALID_PAYMENT_SELECTION', isValid);
};

export const confirmPaymentSelection = async ({ commit, state }) => {
  try {
    const payment = state.data?.selectedPayment;
    const paymentEndpoint = '/shop/checkout/ajax/steps/payment';
    let paymentPayload = qs.stringify({ ...payment });

    if (payment.name === 'afterpay') {
      const afterpayPayload = {
        selection: payment.selection,
        ...(payment.selection === 2 && { iban: payment.iban, bic: payment.bic }),
      };
      paymentPayload = qs.stringify({ ...payment, ...afterpayPayload });
    }

    const result = await AjaxService.post(
      paymentEndpoint,
      paymentPayload
    );
    if (result.data) {
      commit('SET_PAYMENT', { payment: result.data.result.payment });
      commit('UPDATE_CHECKOUT_STEP', { step: result.data.result.step });
      await updateCheckoutURL('confirmation');
    }
    return result;
  } catch (err) {
    console.error('Error confirming payment selection:', err);
    return {
      err,
    };
  }
};

export const selectShippingOption = async (
  { commit, rootState },
  { selectedShippingOption }
) => {
  try {
    const { fetchedUrls } = rootState.core;
    const url = fetchedUrls[CHECKOUT_SHIPPING_OPTION];

    const payload = { shipping_option: selectedShippingOption };

    const result = await AjaxService.post(url, qs.stringify(payload));

    if (result.data && result.data.result) {
      commit('checkout/UPDATE_CHECKOUT_SHIPPING', result.data.result.shipping, {
        root: true,
      });
      commit('checkout/UPDATE_CHECKOUT_CART', result.data.result.cart, {
        root: true,
      });
    }

    return result;
  } catch (error) {
    console.error(
      'Error updating shipping option:',
      error.response ? error.response.data : error
    );
    return null;
  }
};

export const confirmCheckout = async ({ rootState }, { shippingOption, referenceNumber }) => {
  try {
    const { fetchedUrls } = rootState.core;
    const url = fetchedUrls[CHECKOUT_STEP_CONFIRMATION].replace(
      '{stepId}',
      'confirmation'
    );
    const payload = {
      shipping_option: shippingOption,
    };

    if (referenceNumber) payload.referenceNumber = referenceNumber;

    const result = await AjaxService.post(url, qs.stringify(payload));

    return result;
  } catch (error) {
    console.error(
      'Error in  confirmCheckout:',
      error.response ? error.response.data : error
    );
    return null;
  }
};

export const updateWishlist = async (
  { commit, state, rootState },
  { product, stepId }
) => {
  const { fetchedUrls } = rootState.core;
  const payload = {
    sku: product.sku,
    carTypeNumber: product.carTypeNumber,
    amount: !product.inWishlist ? product.quantityAmount : 0,
    productId: product.productId,
    stepId: stepId,
  };

  const result = await AjaxService.post(
    fetchedUrls[CHECKOUT_WISHLIST_ADD_ENDPOINT_KEY],
    qs.stringify(payload)
  );

  if (result.data) {
    commit('checkout/UPDATE_CHECKOUT_CART', result.data.result.cart, {
      root: true,
    });
  }
};

export const updateQuantity = async (
  { rootState, state, commit },
  { product, amount, stepId, listOfOrigin }
) => {
  const { fetchedUrls } = rootState.core;

  const payload = {
    sku: product.sku,
    carTypeNumber: product.carTypeNumber,
    amount,
    productId: product.productId,
    stepId: stepId,
    cartId: state.cartId,
  };

  if (listOfOrigin) {
    payload.list = listOfOrigin;
  }

  const result = await AjaxService.post(
    fetchedUrls[CHECKOUT_UPDATE_QUANTITY_ENDPOINT_KEY].replace(
      '{cartId}',
      state.cartId
    ),
    qs.stringify(payload)
  );

  if (result.redirect) {
    window.location.href = result.redirect;
  } else if (result.data) {
    commit('checkout/UPDATE_CHECKOUT_CART', result.data.result.cart, {
      root: true,
    });
    commit('checkout/UPDATE_CHECKOUT_SHIPPING', result.data.result.shipping, {
      root: true,
    });
  } else {
    console.error('Error updating quantity:', result);
  }
};

/**
 * [andrei] Update the url for the checkout section based on the step the user is on
 * @param step {String}
 * @returns {Promise<void>}
 */
export const updateCheckoutURL = async (step) => {
  if (!['address', 'payment', 'confirmation'].includes(step)) return;

  if (typeof window !== undefined) {
    window.history.pushState('', '', `/shop/checkout/details/${step}`);
  }
};
