<i18n src="./translations"></i18n>

<template>
  <div class="progress-bar" :class="{ 'progress-bar--hidden': percent >= 100 }">
    <div class="progress-bar__header">
      {{ $t('progress_header_2') }}
    </div>

    <div class="progress-bar__inner">
      <div class="progress-bar__percent" :style="`width: ${percent}%`">
        <span v-if="percent > 50" class="progress-bar__text">
          {{ $t('only', { '%amount%': formattedAmount }) }}
        </span>
      </div>
      <span v-if="percent <= 50" class="progress-bar__text">
        {{ $t('only', { '%amount%': formattedAmount }) }}
      </span>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { formatPrice } from 'Libs/helpers/format-price';

export default {
  name: 'ProgressBar',
  mixins: [globalMixin],
  props: {
    amountLackingForFreeShipping: {
      type: Number,
      required: true,
    },
    standardFreeShippingTotal: {
      type: Number,
      required: true,
    },
  },
  computed: {
    percent() {
      return Math.round(
        ((this.standardFreeShippingTotal - this.amountLackingForFreeShipping) /
          this.standardFreeShippingTotal) *
          100
      );
    },
    formattedAmount() {
      return formatPrice(this.amountLackingForFreeShipping);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.progress-bar {
  width: 100%;
  margin-bottom: var(--space-1);

  &--hidden {
    display: none;
  }
}

.progress-bar__header {
  color: var(--color-nero);
  font-size: var(--font-size-S);
  font-weight: bold;
}

.progress-bar__inner {
  height: 25px;
  padding: var(--space-0--half) 0;
  background: var(--color-wild-sand);
  border-radius: var(--space-1);
  display: flex;
  box-shadow: inset 0 0 0 rgba(#7f7373, 4), 0 -1px 1px #ffffff, 0 1px 0 #ffffff;
}

.progress-bar__percent {
  height: 17px;
  border-top-left-radius: var(--space-1);
  border-bottom-left-radius: var(--space-1);
  width: 100%;
  background-color: var(--color-secondary);
  transition: 0.8s linear;
  transition-property: width, background-color;
  color: var(--color-white);
  font-weight: 700;
  text-align: right;
}

.progress-bar__text {
  padding: 0 7px;
  font-weight: bold;
}
</style>
