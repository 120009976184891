<i18n src="./translations"></i18n>

<template>
  <form class="caris-selection-form">
    <span class="caris-selection-form__headline">
      {{ title || $t('caris-selection-form.headline') }}
    </span>
    <CarisSelect
      class="caris-selection-form__select"
      :option-groups="manufacturersOptions"
      :selection="selections.manufacturer"
      :label="$t('caris-selection-form.choose.manufacturer')"
      :disabled="isManufacturerDisabled"
      :qa-class-name-prefix="qaClassName + 'manufacturer'"
      :gtm-class-name="gtmClassName ? gtmClassName + 'manufacturer' : ''"
      @change="(value) => handleSelectChange('manufacturer', value)"
    />
    <CarisSelect
      class="caris-selection-form__select"
      :option-groups="modelsOptions"
      :selection="selections.model"
      :label="$t('caris-selection-form.choose.model')"
      :disabled="isModelDisabled"
      :qa-class-name-prefix="qaClassName + 'model'"
      :gtm-class-name="gtmClassName ? gtmClassName + 'model' : ''"
      @change="(value) => handleSelectChange('model', value)"
    />
    <CarisSelect
      class="caris-selection-form__select"
      :option-groups="typesOptions"
      :selection="selections.type"
      :label="$t('caris-selection-form.choose.type')"
      :disabled="isTypeDisabled"
      :qa-class-name-prefix="qaClassName + 'type'"
      :gtm-class-name="gtmClassName ? gtmClassName + 'type' : ''"
      @change="(value) => handleSelectChange('type', value)"
    />
  </form>
</template>

<script>
import CarisSelect from 'Components/01-atoms/caris-select/CarisSelect';

import globalMixin from 'Libs/mixins/globalMixin';
import qaClassNameMixin from 'Libs/mixins/qaClassNameMixin';

export default {
  name: 'CarisSelectionForm',
  components: { CarisSelect },
  mixins: [globalMixin, qaClassNameMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    manufacturers: {
      type: Object,
      required: true,
      default: () => ({
        clusters: {},
      }),
    },
    models: {
      type: Object,
      required: true,
      default: () => ({
        clusters: {},
      }),
    },
    types: {
      type: Object,
      required: true,
      default: () => ({
        clusters: {},
      }),
    },
    selections: {
      type: Object,
      required: true,
      default: () => ({
        manufacturer: null,
        model: null,
        type: null,
      }),
    },
  },
  computed: {
    manufacturersOptions() {
      return this.manufacturers && this.manufacturers.clusters;
    },
    modelsOptions() {
      return this.models && this.models.clusters;
    },
    typesOptions() {
      return this.types && this.types.clusters;
    },
    isManufacturerDisabled() {
      return !this.manufacturers;
    },
    isModelDisabled() {
      return !this.selections.manufacturer;
    },
    isTypeDisabled() {
      return !this.selections.model;
    },
  },
  methods: {
    handleSelectChange(changedSelect, value) {
      this.selections[changedSelect] = value;

      if (changedSelect === 'manufacturer') {
        this.selections.model = null;
        this.selections.type = null;
      } else if (changedSelect === 'model') {
        this.selections.type = null;
      }

      this.$emit('change', { changedSelect, value });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.caris-selection-form {
  --regular-font-size: var(--font-size-SM);
  --regular-line-height: var(--font-size-M);
  --headline-font-size: var(--font-size-M);
  --headline-line-height: var(--font-size-ML);
  --input-height: 32px;
}

.caris-selection-form__headline {
  width: 100%;
  font-size: var(--font-size-MML);
  line-height: var(--headline-line-height);
  color: var(--color-secondary);
  font-weight: bold;
  letter-spacing: -0.16px;
}

.caris-selection-form__select {
  margin-top: var(--space-1);

  ::v-deep {
    .caris-select__select {
      font-size: var(--regular-font-size);
      height: var(--input-height);
    }

    .caris-select__label {
      font-size: var(--regular-font-size);
      line-height: var(--regular-line-height);
    }
  }
}

@media (max-width: #{$_widthXXLFrom}) {
  .caris-selection-form {
    --headline-font-size: var(--font-size-SM);
    --headline-line-height: var(--font-size-M);
    --regular-font-size: var(--font-size-SM);
  }
}

@media #{$_mediaMDown} {
  .caris-selection-form {
    --headline-font-size: var(--font-size-SM);
    --headline-line-height: var(--font-size-SM);
    --regular-font-size: var(--font-size-M);
    --regular-line-height: var(--font-size-ML);
    --input-height: 40px;
  }

  .caris-selection-form__headline {
    font-weight: bold;
    color: var(--color-white);
  }

  .caris-selection-form__select ::v-deep {
    .caris-select__label,
    .caris-select__icon {
      top: 10px;
    }

    .caris-select__icon {
      @include override-icon-dimensions(24px);
    }
  }
}

:root[data-theme='tirendo'] {
  .caris-selection-form__headline {
    color: var(--color-tirendo-blackout);
  }
}
</style>
