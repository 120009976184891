<i18n src="./translations"></i18n>

<template>
  <div
    :data-preload="[$options.name]"
    class="amounts-price-summary"
    :class="[
      classes,
      {
        'amounts-price-summary--rebranding': isRebrandingChannel,
      },
    ]"
  >
    <template v-if="isBusinessCustomer">
      <div class="amounts-summary-container">
        <table>
          <tbody>
            <tr class="amount-row">
              <th>{{ $t('amounts-price-summary.labels.subtotal-b-plus') }}</th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedSubtotal,
                  })
                }}
              </td>
            </tr>

            <template v-for="discountCode in formattedDiscounts">
              <tr
                v-if="
                  (discountCode.code || discountCode.label) &&
                  discountCode.value
                "
                :key="discountCode.code"
                class="amount-row"
              >
                <th class="th-multiple-items">
                  {{
                    $t('amounts-price-summary.labels.discount') +
                    ': ' +
                    (discountCode.code || discountCode.label)
                  }}
                  <Button
                    icon="delete"
                    class="remove-discount-btn"
                    :icon-width="15"
                    :icon-height="17"
                    :on-click="() => toggleRemoveDiscountDialog(discountCode)"
                  />
                </th>
                <td>
                  {{
                    '-' +
                    $t('amounts-price-summary.amount-with-currency', {
                      '%amount%': discountCode.value,
                    })
                  }}
                </td>
              </tr>
            </template>
            <tr
              v-for="tax in formattedTaxes"
              :key="tax.type"
              class="amount-row"
            >
              <th>
                {{ tax.label }}
              </th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': tax.value,
                  })
                }}
              </td>
            </tr>

            <tr class="amount-row">
              <th>{{ $t('amounts-price-summary.labels.shipping-cost') }}</th>
              <td data-testid="ShippingCost">
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedShipping,
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else>
      <div class="amounts-summary-container">
        <table>
          <tbody>
            <tr class="amount-row">
              <th>{{ $t('amounts-price-summary.labels.subtotal') }}</th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedSubtotal,
                  })
                }}
              </td>
            </tr>
            <tr class="amount-row">
              <th>{{ $t('amounts-price-summary.labels.shipping-cost') }}</th>
              <td data-testid="ShippingCost">
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedShipping,
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <ProgressBar
      :amount-lacking-for-free-shipping="amounts.amountLackingForFreeShipping"
      :standard-free-shipping-total="amounts.standardFreeShippingTotal"
      class="cart-amounts__progress-bar"
      :class="[{ 'cart-amounts__progress-bar-checkout': isCheckout }]"
    />

    <template v-if="isBusinessCustomer">
      <div class="amounts-summary-container">
        <table>
          <tbody>
            <tr v-if="amounts.paymentExpenses" class="amount-row">
              <th>{{ $t('amounts-price-summary.labels.additional-cost') }}</th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedAdditional,
                  })
                }}
              </td>
            </tr>
            <tr v-if="amounts.bulkyGoods" class="amount-row">
              <th class="th-multiple-items">
                {{ $t('amounts-price-summary.labels.bulky-goods-fee') }}
                <button
                  class="bulky-goods-fee__dialog-button"
                  @click="toggleBulkyGoodsDialog()"
                >
                  <Icon name="Help" />
                </button>
              </th>

              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedBulkyGoods,
                  })
                }}
              </td>
            </tr>
            <tr v-if="amounts.riskyGoods" class="amount-row">
              <th class="th-multiple-items">
                {{ $t('amounts-price-summary.labels.risky-goods-fee') }}
                <button
                  class="risky-goods-fee__dialog-button"
                  @click="toggleRiskyGoodsDialog"
                >
                  <Icon name="Help" />
                </button>
              </th>

              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedRiskyGoods,
                  })
                }}
              </td>
            </tr>
            <tr v-if="dropshippingItems" class="dropshipment">
              <td class="dropshipment-container">
                <Icon
                  name="explanation-blue"
                  width="20"
                  class="accordion_icon icon"
                />
                <span class="message">
                  {{ $t('amounts-price-summary.dropshipment_message') }}</span
                >
              </td>
            </tr>
            <tr class="amount-row-total">
              <th>
                {{ $t('amounts-price-summary.labels.total') }}
              </th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedSum,
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <Dialog
          ref="removeDiscountDialogRef"
          class="risky-goods-fee__dialog"
          :click-outside="true"
          :title="$t('amounts-price-summary.discount-dialog.title')"
          :is-rebranding-channel="isRebrandingChannel"
        >
          <div>
            {{
              $t('amounts-price-summary.discount-dialog.content', {
                '%code%': codeToBeRemoved.code,
              })
            }}
            <div class="delete-discount-dialog-btn">
              <Button
                :title="$t('amounts-price-summary.discount-dialog.cancel')"
                modifier="secondary"
                :on-click="closeRemoveDiscountDialog"
              />
              <Button
                :title="$t('amounts-price-summary.discount-dialog.accept')"
                modifier="primary"
                class="confirm-delete-btn"
                :on-click="confirmDiscountRemoval"
              />
            </div>
          </div>
        </Dialog>
      </div>
    </template>
    <template v-else>
      <div class="amounts-summary-container">
        <table>
          <tbody>
            <tr v-if="amounts.paymentExpenses" class="amount-row">
              <th>{{ $t('amounts-price-summary.labels.additional-cost') }}</th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedAdditional,
                  })
                }}
              </td>
            </tr>
            <tr v-if="amounts.bulkyGoods" class="amount-row">
              <th class="th-multiple-items">
                {{ $t('amounts-price-summary.labels.bulky-goods-fee') }}
                <button
                  class="bulky-goods-fee__dialog-button"
                  @click="toggleBulkyGoodsDialog()"
                >
                  <Icon name="Help" />
                </button>
              </th>

              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedBulkyGoods,
                  })
                }}
              </td>
            </tr>
            <tr v-if="amounts.riskyGoods" class="amount-row">
              <th class="th-multiple-items">
                {{ $t('amounts-price-summary.labels.risky-goods-fee') }}
                <button
                  class="risky-goods-fee__dialog-button"
                  @click="toggleRiskyGoodsDialog"
                >
                  <Icon name="Help" />
                </button>
              </th>

              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedRiskyGoods,
                  })
                }}
              </td>
            </tr>
            <template v-for="discountCode in formattedDiscounts">
              <tr
                v-if="
                  (discountCode.code || discountCode.label) &&
                  discountCode.value
                "
                :key="discountCode.code"
                class="amount-row"
              >
                <th class="th-multiple-items">
                  {{
                    $t('amounts-price-summary.labels.discount') +
                    ': ' +
                    (discountCode.code || discountCode.label)
                  }}
                  <Button
                    icon="delete"
                    class="remove-discount-btn"
                    :icon-width="15"
                    :icon-height="17"
                    :on-click="() => toggleRemoveDiscountDialog(discountCode)"
                  />
                </th>
                <td>
                  {{
                    '-' +
                    $t('amounts-price-summary.amount-with-currency', {
                      '%amount%': discountCode.value,
                    })
                  }}
                </td>
              </tr>
            </template>
            <tr v-if="dropshippingItems" class="dropshipment">
              <td class="dropshipment-container">
                <Icon
                  name="explanation-blue"
                  width="20"
                  class="accordion_icon icon"
                />
                <span class="message">
                  {{ $t('amounts-price-summary.dropshipment_message') }}</span
                >
              </td>
            </tr>
            <tr class="amount-row-total">
              <th>
                {{ $t('amounts-price-summary.labels.total') }}
              </th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedSum,
                  })
                }}
              </td>
            </tr>
            <tr class="amount-row-small">
              <th>
                {{ $t('amounts-price-summary.net') + ' ' }}
              </th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': formattedNet,
                  })
                }}
              </td>
            </tr>
            <tr
              v-for="tax in formattedTaxes"
              :key="tax.type"
              class="amount-row-small"
              data-testid="tax-label"
            >
              <th>
                {{ tax.label }}
              </th>
              <td>
                {{
                  $t('amounts-price-summary.amount', {
                    '%amount%': tax.value,
                  })
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <Dialog
          ref="removeDiscountDialogRef"
          class="risky-goods-fee__dialog"
          :click-outside="true"
          :title="$t('amounts-price-summary.discount-dialog.title')"
          :is-rebranding-channel="isRebrandingChannel"
        >
          <div>
            {{
              $t('amounts-price-summary.discount-dialog.content', {
                '%code%': codeToBeRemoved.code,
              })
            }}
            <div class="delete-discount-dialog-btn">
              <Button
                :title="$t('amounts-price-summary.discount-dialog.cancel')"
                modifier="secondary"
                :on-click="closeRemoveDiscountDialog"
              />
              <Button
                :title="$t('amounts-price-summary.discount-dialog.accept')"
                modifier="primary"
                class="confirm-delete-btn"
                :on-click="confirmDiscountRemoval"
              />
            </div>
          </div>
        </Dialog>
      </div>
    </template>
    <Dialog
      ref="riskyGoodsDialogRef"
      class="risky-goods-fee__dialog"
      :click-outside="true"
      :title="$t('amounts-price-summary.labels.risky-goods-fee')"
      :is-rebranding-channel="isRebrandingChannel"
    >
      <div class="risky-goods-fee__dialog-description">
        {{ $t('amounts-price-summary.descriptions.risky-goods-fee') }}
      </div>
    </Dialog>
    <Dialog
      ref="bulkyGoodsDialogRef"
      class="bulky-goods-fee__dialog"
      :title="$t('amounts-price-summary.labels.bulky-goods-fee')"
      :is-rebranding-channel="isRebrandingChannel"
    >
      <div class="bulky-goods-fee__dialog-description">
        {{ $t('amounts-price-summary.descriptions.bulky-goods-fee') }}
      </div>
    </Dialog>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { normalizePrice } from 'Libs/helpers/format-price';
import { mapGetters, mapState } from 'vuex';

import Button from 'Components/01-atoms/button/Button.vue';
import Icon from 'Components/00-generated/Icon';
import ProgressBar from 'Components/01-atoms/progress-bar/ProgressBar.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';

export default {
  name: 'AmountsPriceSummary',
  components: {
    Icon,
    ProgressBar,
    Dialog,
    Button,
  },
  mixins: [globalMixin],
  props: {
    amounts: {
      type: Object,
      default: () => ({
        amountLackingForFreeShipping: 0,
        discount: {
          codes: [],
        },
      }),
      required: true,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    getRegularShippingCost: {
      type: String,
      default: '',
    },
    getExpressShippingCost: {
      type: String,
      default: '',
    },
    selectedShippingOption: {
      type: String,
      default: 'shipment_regular',
    },
  },
  data() {
    return {
      codeToBeRemoved: {},
    };
  },
  computed: {
    ...mapGetters('core', ['isRebrandingChannel', 'isBusinessCustomer']),
    ...mapState('core', {
      currency: (state) => state.currency,
    }),
    formattedSubtotal() {
      return normalizePrice(
        this.amounts.salesValueTotalGross || 0,
        this.currency
      );
    },
    formattedShipping() {
      let shippingTotal;

      if (this.getRegularShippingCost) {
        shippingTotal =
          this.selectedShippingOption === 'shipment_regular'
            ? this.getRegularShippingCost
            : this.getExpressShippingCost;
      } else {
        shippingTotal =
          this.selectedShippingOption === 'shipment_regular'
            ? this.amounts.baseShipping
            : this.amounts.expressShipping;
      }

      return normalizePrice(shippingTotal || 0, this.currency);
    },
    formattedSum() {
      return normalizePrice(this.amounts.grandTotalGross, this.currency);
    },
    formattedNet() {
      return normalizePrice(this.amounts.grandTotalNet, this.currency);
    },
    formattedAdditional() {
      const additionalTotal = Object.values(
        this.amounts.paymentExpenses
      ).reduce((total, value) => total + value, 0);
      return normalizePrice(additionalTotal, this.currency);
    },
    formattedBulkyGoods() {
      return normalizePrice(this.amounts.bulkyGoods, this.currency);
    },
    formattedRiskyGoods() {
      return normalizePrice(this.amounts.riskyGoods, this.currency);
    },
    formattedDiscounts() {
      if (!this.amounts.discount && !this.amounts.discount?.codes) {
        return [];
      }

      return this.amounts.discount.codes;
    },
    formattedTaxes() {
      return this.amounts?.taxValues?.map((tax) => {
        return {
          label: tax.label,
          value: normalizePrice(tax.value, this.currency),
        };
      });
    },
  },
  methods: {
    toggleBulkyGoodsDialog() {
      this.$refs.bulkyGoodsDialogRef.show();
    },
    toggleRiskyGoodsDialog() {
      this.riskyGoodsDialogRef = !this.riskyGoodsDialogRef;
      this.$refs.riskyGoodsDialogRef.show();
    },
    toggleRemoveDiscountDialog(discount) {
      this.codeToBeRemoved = discount;
      this.$refs.removeDiscountDialogRef.show();
    },
    closeRemoveDiscountDialog() {
      this.$refs.removeDiscountDialogRef.hide();
    },
    confirmDiscountRemoval() {
      this.$emit('removeDiscount', this.codeToBeRemoved);
      this.$refs.removeDiscountDialogRef.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';
@import 'variables/breakpoints';
@import 'utils/fonts';

.amounts-price-summary {
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 350px;
}

$values-exclusion: 'p:not(:last-child):not(.values__net):not(.values__tax):not(.values__total)';

.bulky-goods-fee__dialog-button,
.risky-goods-fee__dialog-button {
  height: 100%;
  width: var(--font-size-M);
  padding: var(--space-0);
  margin-left: var(--space-0--half);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.amounts-summary-container {
  display: flex;
  width: 100%;

  & table {
    width: 100%;
  }

  & tbody {
    width: 100%;
  }
}

.discount-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.amount-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-M);
  line-height: var(--font-size-MLL);
  color: var(--color-nero);
  margin-bottom: var(--space-1);
  white-space: nowrap;
}

.amount-row-total {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-ML);
  line-height: var(--font-size-MLL);
  padding: var(--space-2--half) 0 var(--space-1);
  font-weight: 700;
}

.amount-row-small {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-S);
  line-height: var(--font-size-M);
  color: var(--color-mine-shaft);
}

.th-multiple-items {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.remove-discount-btn {
  ::v-deep .inner {
    border: 1px solid #dddddd;
    border-radius: 50%;
    width: 28px !important;
    height: 28px;
    background: var(--color-wild-sand);
    margin-left: var(--space-0--half);
    margin-right: var(--space-0--half);
    padding: 0 !important;

    & .icon > path {
      fill: var(--color-rolling-stone);
    }
  }
}

.dropshipment {
  position: relative;
  background: var(--color-rebranding-semantic-light-blue);
  border-radius: 3px;
  font-size: var(--font-size-M);
  line-height: var(--space-2--half);

  .dropshipment-container {
    display: flex;
    align-items: flex-start;
    padding: var(--space-1);
  }

  .icon {
    margin-right: var(--space-1);
  }

  .message {
    max-width: 250px;
    display: block;
    color: var(--color-nero);
    font-size: var(--font-size-M);
    line-height: var(--space-2--half);
  }
}

.delete-discount-dialog-btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: var(--space-2);
  align-items: center;
}

.confirm-delete-btn {
  margin-left: var(--space-0--half);
  flex-grow: 1;
}

.cart-amounts__progress-bar-checkout {
  display: none;
}

.amounts-price-summary--rebranding {
  @include rebranding-base;
}

@media #{$_mediaMDown} {
  .labels {
    padding: 0;
  }
}

@media #{$_mediaSDown} {
  .amounts-price-summary {
    width: 100%;
  }
}
</style>
