<i18n src="./translations"></i18n>

<template>
  <div class="cart-amounts">
    <VoucherDialog :voucher="voucher.codes" />
    <div class="cart-amounts__amountsInner">
      <AmountsPriceSummary
        class="cart-amounts__price-summary"
        :amounts="amounts"
        :dropshipping-items="dropshippingItems"
        :is-checkout="isCheckout"
        :get-regular-shipping-cost="getRegularShippingCost"
        :get-express-shipping-cost="getExpressShippingCost"
        :selected-shipping-option="selectedShippingOption"
        @removeDiscount="handleDiscountRemoval"
      />

      <div
        class="cart-amounts__button-section"
        :class="[{ 'cart-amounts__button-section-checkout': isCheckout }]"
      >
        <Button
          class="cart-amounts__checkout-button qa-Cart__cart"
          modifier="primary"
          :title="$t('checkout')"
          :href="frontFacingPath('checkout.checkout.details')"
        />

        <Button
          class="cart-amounts__share-button qa-Cart__share"
          modifier="secondary"
          :title="$t('share_cart.title')"
          icon="paper_plane"
          :on-click="showCartShareDialog"
        />

        <ShareLinkDialog
          class="cart-amounts__share-dialog"
          :active="dialogState.share"
          :title="$t('share_cart.title')"
          type="cart"
          :link-id="cartId"
          @close="dialogState.share = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';
import VoucherDialog from 'Components/02-molecules/voucher-dialog/VoucherDialog.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import ShareLinkDialog from 'Components/02-molecules/share-link-dialog/ShareLinkDialog.vue';
import AmountsPriceSummary from 'Components/01-atoms/amounts-price-summary/AmountsPriceSummary.vue';

export default {
  name: 'CartAmounts',
  components: {
    VoucherDialog,
    Button,
    ShareLinkDialog,
    AmountsPriceSummary,
  },
  mixins: [globalMixin],
  props: {
    amounts: {
      type: Object,
      default: () => ({}),
    },
    dropshippingItems: {
      type: Boolean,
      default: false,
    },
    cartId: {
      type: String,
      default: '',
    },
    voucher: {
      type: Object,
      default: () => ({}),
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    getRegularShippingCost: {
      type: String,
      default: '',
    },
    getExpressShippingCost: {
      type: String,
      default: '',
    },
    selectedShippingOption: {
      type: String,
      default: 'shipment_regular',
    },
  },
  data() {
    return {
      dialogState: { share: false },
    };
  },
  methods: {
    ...mapActions('cart', ['removeDiscount']),
    showCartShareDialog() {
      this.dialogState.share = true;
    },
    async handleDiscountRemoval(discount) {
      await this.removeDiscount({
        discount,
        source: 'cart',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.cart-amounts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.cart-amounts__amountsInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cart-amounts__price-summary ::v-deep .labels {
  padding-left: 0;
}

.cart-amounts__button-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 300px;
  margin: var(--space-1) -6px;
}

.cart-amounts__checkout-button {
  margin: var(--space-0--half);
  min-width: 350px;
  text-transform: none !important;
}

.cart-amounts__button-section-checkout {
  display: none;
}

@media #{$_mediaSDown} {
  .cart-amounts {
    width: 100%;
    min-width: 100%;
    flex-direction: column !important;
  }

  .cart-amounts__button-section {
    margin: 0;
    justify-content: center !important;
  }

  .cart-amounts__checkout-button,
  .cart-amounts__share-button {
    margin: var(--space-1);
    min-width: 250px;
    width: 100% !important;
  }
}

@media #{$_mediaMUp} {
  .cart-amounts__share-button {
    display: none !important;
  }
}
</style>
