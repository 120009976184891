<i18n src="./translations"></i18n>

<template>
  <SimpleDialog
    class="caris-select-vehicle-dialog qa-carisDialog"
    :active="isActive"
    :background="isBackground"
    :title="dialogTitle"
    :message="dialogMessage"
    :gtm-class-name="
      state === DIALOG_STATE.SELECTED_VEHICLE
        ? 'gtm--carisDialogFinalConfirmation-'
        : 'gtm--carisDialog-'
    "
    :action="{
      enabled: true,
      title: dialogActionLabel,
      modifier: 'primary gtm--carisDialog-selectVehicleButton',
    }"
    is-portal-disabled
    @close="handleClose"
    @action-click="handleDialogActionClick"
  >
    <CarisVehicleSelector
      v-if="state === DIALOG_STATE.TO_SELECT_VEHICLE"
      ref="vehicle-selector"
      :parent-form="parentForm"
      :parent-selections="parentSelections"
      @vehicle-select="handleVehicleSelect"
    />
    <template v-if="state === DIALOG_STATE.SELECTED_VEHICLE">
      <CarisVehicleTable :vehicles="selectableVehicles" />
      <div
        v-if="viewport === 'mobile'"
        class="caris-select-vehicle-dialog__divider"
      ></div>
      <span class="caris-select-vehicle-dialog__subheader">
        {{ $t('caris-select-vehicle-dialog.garage_selection.title') }}
      </span>
      <CheckboxBase
        class="caris-select-vehicle-dialog__garage-checkbox"
        :label="$t('caris-select-vehicle-dialog.garage_selection.label')"
        :value="isAdditionToGarage"
        :hide-optional="true"
        @input="handleGarageAdditionChange"
      />
    </template>
    <template v-if="state === DIALOG_STATE.GARAGE_ADDITION">
      <span class="caris-select-vehicle-dialog__subheader-prefix">
        {{ $t('caris-select-vehicle-dialog.garage_selection.input.asterisk') }}
      </span>
      <span
        class="caris-select-vehicle-dialog__subheader caris-select-vehicle-dialog__subheader--garage-addition"
      >
        {{ $t('caris-select-vehicle-dialog.garage_selection.input.label') }}
      </span>
      <InputBase
        ref="vehicle-name"
        class="caris-select-vehicle-dialog__vehicle-name-input qa-carisInput"
        :placeholder="
          $t('caris-select-vehicle-dialog.garage_selection.input.placeholder')
        "
        :value="garageAdditionName"
        @input="handleVehicleNameChange"
      />
    </template>
  </SimpleDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { ktypnrMixin } from 'Libs/mixins/ktypnrMixin';
import globalMixin from 'Libs/mixins/globalMixin';

import SimpleDialog from 'Components/01-atoms/simple-dialog/SimpleDialog';
import CheckboxBase from 'Components/01-atoms/form/checkbox/CheckboxBase';
import InputBase from 'Components/01-atoms/form/input/InputBase';
import CarisVehicleSelector from 'Components/02-molecules/caris-vehicle-selector/CarisVehicleSelector';
import CarisVehicleTable from 'Components/02-molecules/caris-vehicle-table/CarisVehicleTable';

const DIALOG_STATE = {
  GARAGE_ADDITION: 'GARAGE_ADDITION',
  SELECTED_VEHICLE: 'SELECTED_VEHICLE',
  TO_SELECT_VEHICLE: 'TO_SELECT_VEHICLE',
};

export default {
  name: 'CarisSelectVehicleDialog',
  components: {
    SimpleDialog,
    CarisVehicleSelector,
    CarisVehicleTable,
    CheckboxBase,
    InputBase,
  },
  mixins: [globalMixin, ktypnrMixin],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    parentForm: {
      type: Object,
      default: () => ({
        hsnValue: null,
        tsnValue: null,
      }),
    },
    parentSelections: {
      type: Object,
      default: () => ({
        manufacturer: null,
        model: null,
        type: null,
      }),
    },
  },
  data() {
    return {
      isBackground: false,
      isVehicleSelected: false,
      isAdditionToGarage: false,
      isAdditionToGarageSelected: false,
      garageAdditionName: null,
    };
  },
  computed: {
    ...mapGetters('core', {
      config: 'getSalesChannelConfig',
    }),
    ...mapState('carSelection', [
      'selectableVehicles',
      'selectedVehicle',
      'saveToGarageVehicle',
    ]),
    ...mapState('carSelection', {
      isUserLoggedIn: ({ data }) => data.isLoggedIn,
    }),
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
    }),
    DIALOG_STATE() {
      return DIALOG_STATE;
    },
    state() {
      let state;

      if (this.isAdditionToGarageSelected) {
        state = DIALOG_STATE.GARAGE_ADDITION;
      } else if (this.isVehicleSelected) {
        state = DIALOG_STATE.SELECTED_VEHICLE;
      } else {
        state = DIALOG_STATE.TO_SELECT_VEHICLE;
      }

      return state;
    },
    isNewHeaderAndLicensePlateSearch() {
      return this.config.carisLicenseSearch;
    },
    isNewHeaderAndNatCodeSearch() {
      return this.config.carisNatCodeSearch;
    },
    dialogTitle() {
      let title;

      if (this.state === DIALOG_STATE.TO_SELECT_VEHICLE) {
        title = this.$t(
          'caris-select-vehicle-dialog.headline.to_select_vehicle'
        );
      } else if (this.state === DIALOG_STATE.SELECTED_VEHICLE) {
        title = this.$t(
          'caris-select-vehicle-dialog.headline.selected_vehicle'
        );
      } else if (this.state === DIALOG_STATE.GARAGE_ADDITION) {
        title = this.$t('caris-select-vehicle-dialog.headline.garage_addition');
      }

      return title;
    },
    dialogMessage() {
      let message;

      if (this.state === DIALOG_STATE.TO_SELECT_VEHICLE) {
        message = this.$t(
          'caris-select-vehicle-dialog.message.to_select_vehicle'
        );
      } else if (this.state === DIALOG_STATE.SELECTED_VEHICLE) {
        message = this.$t(
          'caris-select-vehicle-dialog.message.selected_vehicle'
        );
      } else if (this.state === DIALOG_STATE.GARAGE_ADDITION) {
        message = this.$t(
          'caris-select-vehicle-dialog.message.garage_addition'
        );
      }

      return message;
    },
    dialogActionLabel() {
      let label;

      if (this.state === DIALOG_STATE.TO_SELECT_VEHICLE) {
        label = this.$t('caris-select-vehicle-dialog.action.title.select');
      } else if (this.state === DIALOG_STATE.SELECTED_VEHICLE) {
        label = this.$t('caris-select-vehicle-dialog.action.title.accept');
      } else if (this.state === DIALOG_STATE.GARAGE_ADDITION) {
        label = this.$t('caris-select-vehicle-dialog.action.title.accept');
      }

      return label;
    },
  },
  watch: {
    async isActive() {
      if (this.isActive) {
        // [ozlui] redirects dialog to the garage addition step if "saveToGarageVehicle" from store is truthy
        if (this.saveToGarageVehicle && this.saveToGarageVehicle.ktypnr) {
          const { ktypnr, garageName } = this.saveToGarageVehicle;
          this.updateSelectedVehicle({ vehicle: { ktypnr } });
          this.garageAdditionName = garageName;
          this.isAdditionToGarageSelected = true;
        } else {
          const { hsnValue, tsnValue } = this.parentForm;
          const { natCodeValue } = this.parentForm;
          const { type } = this.parentSelections;
          if ((hsnValue && tsnValue) || type || natCodeValue) {
            await this.$nextTick();

            type ? this.handleTypeChange() : this.handleSelectAction();
          }
        }
      } else {
        this.isVehicleSelected = false;
        this.garageAdditionName = null;
        this.isAdditionToGarage = false;
        this.isAdditionToGarageSelected = false;
        this.garageAdditionName = null;
        this.updateSaveToGarage({ ktypnr: null, garageName: null });
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', this.closeDialogWithKeyPress);
  },
  unmounted() {
    document.removeEventListener('keyup', this.closeDialogWithKeyPress);
  },
  methods: {
    ...mapActions('carSelection', [
      'loadCarisData',
      'doSaveCarToGarage',
      'updateSaveToGarage',
    ]),
    ...mapMutations('carSelection', {
      updateSelectedVehicle: 'CARIS_NEW_VEHICLE__SELECT_VEHICLE',
    }),
    handleVehicleSelect() {
      this.isVehicleSelected =
        this.selectableVehicles && this.selectableVehicles.length > 0;
    },
    handleClose() {
      this.$emit('close');
    },
    handleSelectAction() {
      const selectorEl = this.$refs['vehicle-selector'];

      if (this.isNewHeaderAndNatCodeSearch) {
        const natCodeEl = selectorEl?.$refs.natCodePlateSearch;
        const natCodeInputEl = natCodeEl?.$refs.nat;

        if (natCodeInputEl?.value !== '') {
          natCodeEl?.handleSubmit();
        }
      } else if (this.isNewHeaderAndLicensePlateSearch) {
        const licensePlateEl = selectorEl?.$refs['license-plate'];
        const typeMineEl = selectorEl?.$refs['mine-search'];

        const licensePlateValue = licensePlateEl?.$refs.licensePlate.value;
        const typeMineValue = typeMineEl?.$refs.typeMine.value;

        if (licensePlateValue !== '') {
          licensePlateEl?.doLicenseRequest();
        } else if (typeMineValue !== '') {
          typeMineEl?.searchWithTypeMine();
        }
      } else {
        selectorEl?.$refs['hsn-tsn'].submitForm();
      }
    },
    handleTypeChange() {
      // TODO [izerozlu] need to find a better usage for this
      this.$refs['vehicle-selector']?.handleTypeChange();
    },
    handleAcceptAction(checkForAdditionToGarage = true) {
      if (checkForAdditionToGarage && this.isAdditionToGarage) {
        this.isAdditionToGarageSelected = true;
      } else if (this.selectedVehicle) {
        const { id, ktypnr } = this.selectedVehicle;
        this.setKtypnr(ktypnr || id);
      }
    },
    async handleGarageAdditionAction() {
      const ktypnr = this.selectedVehicle.ktypnr || this.selectedVehicle.id;

      if (this.garageAdditionName) {
        if (this.isUserLoggedIn) {
          await this.doSaveCarToGarage({
            ktypnr,
            displayName: this.garageAdditionName,
          });

          this.updateSaveToGarage({ ktypnr: null, garageName: null });
          this.handleAcceptAction(false);
        } else {
          this.updateSaveToGarage({
            ktypnr,
            garageName: this.garageAdditionName,
          });
          location.href = this.frontFacingPath('customer.shop.login');
        }
      } else {
        this.$refs['vehicle-name'].focus();
        this.$refs['vehicle-name'].handleInvalidInput();
      }
    },
    handleDialogActionClick() {
      if (this.state === DIALOG_STATE.SELECTED_VEHICLE) {
        this.handleAcceptAction();
      } else if (this.state === DIALOG_STATE.TO_SELECT_VEHICLE) {
        this.handleSelectAction();
      } else if (this.state === DIALOG_STATE.GARAGE_ADDITION) {
        this.handleGarageAdditionAction();
      }
    },
    handleGarageAdditionChange() {
      this.isAdditionToGarage = !this.isAdditionToGarage;
    },
    handleVehicleNameChange(value) {
      this.garageAdditionName = value;
    },
    closeDialogWithKeyPress(event) {
      if (event.key === 'Escape') {
        this.handleClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.caris-select-vehicle-dialog__hsn-tsn {
  ::v-deep {
    .caris-hsn-tsn__headline {
      color: var(--color-nero);
      font-weight: bold;
    }

    .caris-hsn-tsn__form {
      flex-wrap: nowrap;
      max-width: unset;
      align-items: flex-end;

      .form__field {
        width: 160px;

        label {
          max-width: 145px;
        }

        input {
          width: 160px;
        }
      }

      .form__field:first-child {
        margin-right: 15px;
      }

      .form__field:nth-child(2) {
        margin-right: 10px;
      }

      .form__where-to-find-button {
        color: var(--color-nero);
        height: min-content;
      }
    }
  }
}

.caris-select-vehicle-dialog__selection-form.caris-selection-form {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  ::v-deep {
    .caris-selection-form__headline {
      color: var(--color-nero);
      font-weight: bold;
      flex-basis: 100%;
    }

    .caris-selection-form__select {
      width: 220px;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
}

.caris-select-vehicle-dialog__loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.12);
  z-index: 500;
}

.caris-select-vehicle-dialog__subheader-prefix {
  color: var(--color-monza);
  margin-right: var(--space-0--half);
  line-height: var(--subheader-line-height);
  font-size: var(--subheader-font-size);
}

.caris-select-vehicle-dialog__divider {
  width: 100%;
  border-bottom: 1px solid var(--color-alt);
  margin: var(--space-2) 0;
}

.caris-select-vehicle-dialog__subheader {
  --subheader-font-size: var(--font-size-M);
  --subheader-line-height: var(--font-size-ML);
  --subheader-margin-top: var(--space-4);

  display: block;
  font-weight: bold;
  text-align: left;
  color: var(--color-nero);
  line-height: var(--subheader-line-height);
  font-size: var(--subheader-font-size);
  margin-top: var(--subheader-margin-top);

  &--garage-addition {
    margin-top: 0;
    display: inline;
  }
}

.caris-select-vehicle-dialog__garage-checkbox {
  --message-font-size: var(--font-size-M);

  margin-top: 15px;

  ::v-deep {
    // override CheckboxBase styling

    .checkmark {
      margin-right: 20px !important;
    }

    .label__value {
      font-size: var(--message-font-size) !important;
    }
  }
}

.caris-select-vehicle-dialog__vehicle-name-input {
  margin-top: var(--space-2);

  ::v-deep {
    .inputEl {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media #{$_mediaMDown} {
  .caris-select-vehicle-dialog__subheader {
    --subheader-font-size: var(--font-size-S);
    --subheader-line-height: var(--font-size-SM);
    --subheader-margin-top: 0;

    &--garage-addition {
      --subheader-font-size: var(--font-size-SM);
      --subheader-line-height: var(--font-size-M);
    }
  }

  .caris-select-vehicle-dialog__garage-checkbox {
    --message-font-size: var(--font-size-S);
  }
}
</style>
