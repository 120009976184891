<template>
  <div :class="classes" :data-preload="[$options.name]">
    <button
      v-for="(action, key) in actionsList.actions"
      :key="key"
      :class="['action', action.class]"
      :title="action.title"
      :tabindex="action.tabindex"
      @click="handleActionClick(productId, action.type, action.listOfOrigin)"
    >
      <div>
        <Icon :name="action.icon" height="18" :original="true" class="icon" />
      </div>
    </button>
  </div>
</template>

<script>
import noop from 'lodash/noop';
import Icon from 'Components/00-generated/Icon';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapActions } from 'vuex';

export default {
  name: 'TileActions',
  components: {
    Icon,
  },
  mixins: [globalMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    actionsList: {
      type: Object,
      required: true,
    },
    productId: {
      type: String,
      default: '',
    },
    deleteProduct: {
      type: Function,
      default: noop,
    },
    addressId: {
      type: String,
      default: '',
    }
  },
  methods: {
    handleActionClick(productId, type, listOfOrigin) {
        if (type === 'delete') {
          this.deleteProduct({
            productId,
            listOfOrigin,
          });
        }

      this.$emit('action', { productId, type, listOfOrigin });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.tileActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

::v-deep {
  .icon {
    fill: var(--color-rolling-stone);
  }
}

.action {
  flex-grow: 1;
  border-top: 1px solid var(--color-alto);
  border-right: 1px solid var(--color-alto);
  width: 42px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    background-color: var(--color-secondary);
    border-color: var(--color-secondary);

    ::v-deep {
      .icon,
      .icon g {
        fill: var(--color-white);
      }
    }
  }

  &:last-child {
    border-right: none;
  }

  & * {
    pointer-events: none;
  }
}

.tileActions {
  &--break {
    flex-direction: row;
    width: 100%;

    .action {
      flex-grow: 1;
      border-top: 1px solid var(--color-alto);
      border-left: none;
      border-right: 1px solid var(--color-alto);
      border-bottom: none;

      &:last-child {
        border-right: none;
      }
    }
  }
}

@media #{$_mediaMUp} {
  .tileActions {
    flex-direction: column;
  }

  .action {
    border-left: 1px solid var(--color-alto);
    border-top: none;
    border-bottom: 1px solid var(--color-alto);
    border-right: none;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
